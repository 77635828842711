import React, { FunctionComponent as FC, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  getFromStorage,
  removeFromStorage,
  getMessageAcceptObject,
} from '../util/storage-utils';
import {
  renderPageComponents,
  getHeroComponent,
  getStatusAlertComponent,
} from '../util/contentful-render-utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import { isIE } from 'react-device-detect';
import SupportedBrowserMessage from '../components/supported-browser-message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(10),
      '& .spacingComponent.ContentfulElementColumns2, & .spacingComponent.ContentfulElementColumns3, & .spacingComponent.ContentfulModuleAccordion, & .spacingComponent.ContentfulElementCallout, & .ContentfulModuleRelated, & figure.uiTableExtention, & .spacingComponent.ContentfulElementMessagingBlock, & .spacingComponent.ContentfulElementPullquote, & .spacingComponent.ContentfulElementCta, & .CtaCard, & .GallerySlider, & .spacingComponent.ContentfulModulePromoGroup, & .spacingComponent.ContentfulWrapperembedMedia, & article.WrapperImage, & .spacingComponent.ContentfulHeaderhero, & .spacingComponent.ContentfulHeaderSplit, & .spacingComponent.ContentfulContentBody, & .ContentfulModuleTabset':
        {
          maxWidth: theme.typography.pxToRem(1280),
          marginLeft: `auto`,
          marginRight: `auto`,
        },
      '& .spacingComponent.ContentfulContentBody': {
        padding: theme.spacing(0, 3),
      },
    },
  }),
);

const Home: FC = () => {
  const { page } = useStaticQuery(graphql`
    query homepageContentMVP2 {
      page: allContentfulPageHome(filter: { slug: { eq: "pge-foundation" } }) {
        nodes {
          node_locale
          contentful_id
          entryName
          shortMenuTitle
          slug
          pageTitle
          browserTitle
          header {
            __typename
            entryName
            heading
            headingLineTwo
            subheading
            image {
              file {
                contentType
                fileName
                url
              }
            }
            button {
              buttonText
              buttonType
              buttonIcon {
                file {
                  contentType
                  fileName
                  url
                }
              }
            }
            buttonLink {
              ...BasicLink
              ...HomeLink
            }
          }
          contentEntries {
            ...ModuleContentBody
            ...ModuleCallOut
            ...ModuleColumn2
            ...ModuleColumn3
            ...ModuleCallToAction
            ...ModuleMessagingBlock
            ...ModulePullQuote
            ...ModuleHeroHeader
            ...ModuleAccordion
            ...GallerySlider
            ...ModulePromoGroup
            ...ModuleRelated
            ...ModuleTabSet
            ...ModuleStatusAlert
            ...ModuleImageWrapper
            ...ModuleEmbedMediaWrapper
          }
          metaDescriptionSeo
          statusAlert {
            ...ModuleStatusAlert
          }
        }
      }
    }
  `);
  // grab the current locale.
  const [content] = page.nodes.filter(
    (pg: any) => pg.node_locale === (getFromStorage('locale') || 'en'),
  );

  const classes = useStyles();
  const header = getHeroComponent([content?.header]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const messageAcceptTime = getFromStorage(getMessageAcceptObject.key);

  useEffect(() => {
    if (messageAcceptTime === null) {
      setIsDialogOpen(true);
    } else {
      if (
        messageAcceptTime &&
        new Date().getTime() - Number(messageAcceptTime) >
          getMessageAcceptObject.hours * 60 * 60 * 1000
      ) {
        removeFromStorage(getMessageAcceptObject.key);
        setIsDialogOpen(true);
      }
    }
  }, []);

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{content?.browserTitle || content?.pageTitle}</title>
        {content?.metaDescriptionSeo && (
          <meta name="description" content={content.metaDescriptionSeo} />
        )}
      </Helmet>
      {header}
      <div className={classes.root}>
        {getStatusAlertComponent(content?.statusAlert)}
        {renderPageComponents({
          entriesData: content?.contentEntries || null,
        })}
      </div>
      {isIE && isDialogOpen && (
        <SupportedBrowserMessage
          messageAcceptKey={getMessageAcceptObject.key}
        />
      )}
    </>
  );
};

export default Home;
