/**
 * Displays a Dialog that the user accept the message before viewing the site on IE browser
 * On click of OK button - accepted data is stored in localStorage.
 * Message will not be displayed again unless local storage is clear or
 * Localstorage will be cleared after 30 days
 *
 **/
import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  makeStyles,
  createStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PGELogo from '../logos/PGELogo';
import { setInStorage } from '../../util/storage-utils';

const useStyles = makeStyles(theme =>
  createStyles({
    dialogTitle: { display: 'flex', justifyContent: 'center' },
    logo: { width: 100, height: 100, textAlign: 'center' },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    browserLink: {
      textDecoration: 'none',
    },
  }),
);
type Props = {
  messageAcceptKey: string;
};
const SupportedBrowserMessage = (props: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { messageAcceptKey } = props;
  const handleClose = (_e: any) => {
    setInStorage(messageAcceptKey, JSON.stringify(new Date().getTime()));
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      data-testid="site-intercept"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <PGELogo viewBox="0 0 288 288" className={classes.logo} />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeButton}
          size="small"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          item
          direction={'column'}
          justify={'flex-start'}
          spacing={2}
        >
          <Grid item style={{ marginTop: '1em' }}>
            <Typography variant={'h1'} component={'div'}>
              Welcome!
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'} component={'div'}>
              We notice you're using Internet Explorer.
            </Typography>
          </Grid>
          <Grid item style={{ marginBottom: '1em' }}>
            <Typography variant={'body1'} component={'div'}>
              For your security and the best experience, we recommend using a
              different internet browser such as{' '}
              <a
                className={classes.browserLink}
                href="https://www.google.com/chrome/"
                target="_blank"
              >
                Google Chrome,
              </a>{' '}
              <a
                className={classes.browserLink}
                href="https://support.apple.com/downloads/safari"
                target="_blank"
              >
                Safari,
              </a>{' '}
              <a
                className={classes.browserLink}
                href="https://www.mozilla.org/en-US/firefox/new/"
                target="_blank"
              >
                Firefox,
              </a>{' '}
              <a
                className={classes.browserLink}
                href="https://www.microsoft.com/en-us/edge"
                target="_blank"
              >
                or Microsoft Edge.
              </a>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          variant="contained"
          data-testid="site-intercept-accept-button"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportedBrowserMessage;
